import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import BasePage from '../../../components/basePage'
import { navigate } from 'gatsby'

const ReadyToPlay = ({ bookingId }) => {
	return (
		<BasePage
			showBottombar
			noPadding
			theme="dark"
			access={1}
			flex={1}
			fullWidthActionBtns
			noHeader={false}
			hideSidebar={true}
			primaryBtn={
				<Button
					variant="primary"
					type="submit"
					mx="20px"
					mb="40px"
					onClick={() => navigate(`/manage-booking/${bookingId}`)}
				>
					Back To Group
				</Button>
			}
		>
			<Flex
				px="20px"
				py="10px"
				alignItems="center"
				justifyContent="center"
				direction="column"
				mt="5"
				gap="5"
			>
				<StaticImage
					src="../../../images/icon-ok.png"
					style={{
						width: '80px',
						objectFit: 'contain'
					}}
					alt="ok"
				/>
				<Text as="h1" variant="title" textColor="white">
					YOU'RE READY <br /> TO PLAY!
				</Text>
				<Text textColor="white" textAlign="center" fontSize={18} mb="5">
					Please arrive at the games check-in 5 minutes before your
					games start time.
				</Text>
				<StaticImage
					src="../../../images/cans.png"
					style={{
						width: 80,
						objectFit: 'contain'
					}}
					alt="ok"
				/>
				<Text textColor="white">Have a great time!</Text>
			</Flex>
		</BasePage>
	)
}

export default ReadyToPlay
